import React from 'react';

import { graphql } from 'gatsby';

import UngatedEcommProductPage from '../components/ungated-ecomm-product/ungated-ecomm-product-page';

export const query = graphql`
  query UngatedEcommProductPageQuery($pageId: String!, $breakpoints: [Int!]) {
    kalansoPage(pageId: { eq: $pageId }) {
      ...KalansoFullPageFragment
    }
  }
`;

const UngatedEcommProductTemplate = (props) => {
  return <UngatedEcommProductPage {...props} />;
};

export default UngatedEcommProductTemplate;
