import React from 'react';

import { useHeadless } from '../../context/headless-provider';

import LoadingScreen from '../shared/loading-screen';

const Products = {
  RTUBG: {
    Variants: {
      Control: React.lazy(() => import('./products/rtubg/variants/control')),
      Doldrums: React.lazy(() => import('./products/rtubg/variants/doldrums')),
      Soilentines: React.lazy(() => import('./products/rtubg/variants/soilentines')),
    },
  },
  RTUBJ: {
    Variants: {
      Control: React.lazy(() => import('./products/rtubj/variants/control')),
      HalfGal: React.lazy(() => import('./products/rtubj/variants/halfgal')),
    },
  },
  RTUBB: {
    Variants: {
      Control: React.lazy(() => import('./products/rtubb/variants/control')),
    },
  },
};

const selectProductCode = (context) => {
  return context.settings.find((s) => s.key === 'product.code');
};

const selectProductVariant = (context) => {
  return context.settings.find((s) => s.key === 'product.variant');
};

const UngatedEcommProductPage = (props) => {
  const { data } = props;
  const { kalansoPage } = data;

  const code = useHeadless(selectProductCode);
  const variant = useHeadless(selectProductVariant);

  if ([code, variant].some((i) => Boolean(i) === false)) {
    throw new Error(`Page-${kalansoPage.id} has invalid product code/variant`);
  }

  const Product = Products[code.value].Variants[variant.value];

  return (
    <React.Suspense fallback={<LoadingScreen page={kalansoPage} />}>
      <Product {...props} />
    </React.Suspense>
  );
};

export default UngatedEcommProductPage;
